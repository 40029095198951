<style lang="less" scoped>
.master-view {
  width: 100%;
  background: #070911;
  &-first {
    width: 100%;
    position: relative;
    img {
      width: 100%;
    }
    &-container {
      width: 76.7%;
      max-width: 1104px;
      color: rgba(255, 255, 255, 1);
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -40%);
      p {
        margin: 0;
        font-family: "PingFangSC-Regular", "PingFang SC";
        &:nth-child(1) {
          font-size: 20px;
          font-weight: 400;
          color: rgba(249, 98, 47, 1);
          line-height: 28px;
          letter-spacing: 3px;
        }
        &:nth-child(2) {
          font-size: 55px;
          font-weight: 600;
          color: rgba(255, 255, 255, 1);
          line-height: 60px;
          margin: 18px 0 69px;
        }
        &:nth-child(4) {
          font-size: 18px;
          font-weight: 400;
          color: rgba(255, 255, 255, 1);
          letter-spacing: 1px;
          margin-top: 220px;
        }
      }
    }
  }
  &-second {
    width: 100%;
    // height: 548px;
    &-container {
      width: 88.89%;
      max-width: 1280px;
      height: 200px;
      color: rgba(255, 255, 255, 1);
      margin: 164px auto;
      overflow: hidden;
      position: relative;
      .swiper-wrapper {
        width: 80%;
        margin: 0 10%;
      }
      .swiper-slide {
        width: 100%;
        display: flex;
        justify-content: space-around;
        &-left {
          width: 20%;
          img {
            width: 200px;
          }
        }
        &-right {
          width: 70%;
          p {
            width: 680px;
            margin: 0;
            font-family: "PingFangSC-Regular", "PingFang SC";
            color: rgba(255, 255, 255, 1);
            font-weight: 400;
            &:nth-child(1) {
              font-size: 20px;
              line-height: 28px;
            }
            &:nth-child(2) {
              width: 164px;
              font-size: 20px;
              line-height: 28px;
              margin-top: 30px;
              // margin: 26px 0;
            }
            &:nth-child(3) {
              font-size: 16px;
              line-height: 24px;
            }
          }
        }
      }
    }
  }
  &-third {
    width: 100%;
    height: 800px;
    background: #0f1118;
    &-container {
      width: 76.7%;
      max-width: 1104px;
      color: rgba(255, 255, 255, 1);
      margin: 0 auto;
      &-top {
        padding-top: 95px;
        display: flex;
        justify-content: space-between;
        .search {
          width: 113px;
          height: 40px;
          border-radius: 4px;
          border: 1px solid rgba(222, 230, 255, 1);
          text-align: center;
          line-height: 40px;
          font-size: 10px;
          font-weight: 500;
          letter-spacing: 1px;
          cursor: pointer;
        }
      }
      &-main {
        margin-top: 46px;
      }
      &-main-item {
        width: 100%;
        height: 70px;
        line-height: 70px;
        display: flex;
        justify-content: space-between;
        font-size: 20px;
        font-weight: 400;
        p {
          margin: 0;
        }
        &:hover {
          cursor: pointer;
          background: rgba(7, 9, 17, 1);
        }
      }
    }
  }
  /deep/
    .master-view-second-container.swiper-container-initialized.swiper-container-horizontal {
    .swiper-button-next,
    .swiper-button-prev {
      width: 51px;
      height: 51px;
      background: rgba(222, 230, 255, 1);
      border-radius: 50%;
      color: #0f1118;
      box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
      margin-left: 50px;
      margin-right: 50px;
      &:after {
        font-size: 14px;
        font-weight: bolder;
      }
    }
    .swiper-button-prev {
      left: -40px;
    }
    .swiper-button-next {
      right: -40px;
    }
  }
  .r-button {
    width: 140px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
    background: linear-gradient(
      211deg,
      rgba(248, 40, 108, 1) 0%,
      rgba(249, 98, 47, 1) 100%
    );
    border-radius: 29px;
  }
}
</style>

<template>
  <div class="master-view">
    <div class="master-view-first">
      <img src="../assets/master/home_master.png"
           alt="">
      <div class="master-view-first-container">
        <p>
          长三角创意经济合作专业委员会
        </p>
        <p>
          CREATIVE ECONOMY <br />
          COOPERATION COMMITTEE
        </p>
        <div class="r-button"
             @click="toAboutUsDetails">
          {{$t('readMore')}}
        </div>
        <p>
          战略合作： 荷兰罗斯加德工作室 /Normal Studio /Lucion Média /MASSIVart /Playmind /L'Eloi
        </p>
      </div>
    </div>
    <div class="master-view-second">
      <div class="master-view-second-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide"
               v-for="(item, index) in masterView"
               :key="index">
            <div class="swiper-slide-left">
              <img :src="'/api/static/'+item.avatar"
                   alt="">
            </div>
            <div class="swiper-slide-right">
              <p>
                {{item.description}}
                <!-- “创造价值创新的能力与吸收能力和动态能力密切相关。吸收能力是知识别新信息的价值，消化它，并将其应用于商业目的的能力。动态能力是指企业利用资源来匹配甚至创造变化的过程，动态能力是一种学习到的、稳定的集体活动模式，通过这种模式，组织有系统地产生和修改其操作规程以提高效率。” -->
              </p>
              <p>
                {{item.name}}
                <!-- Paul Matthyssens -->
              </p>
              <p>
                {{item.title}}
                <!-- Dean of University of Antwerp -->
              </p>
            </div>
          </div>
        </div>
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
      </div>
    </div>
    <!-- <news /> -->
    <div class="master-view-third">
      <div class="master-view-third-container">
        <div class="master-view-third-container-top">
          <div>
            <el-input v-model="input"
                      placeholder="搜索大师观点文章关键词"
                      style="width: 550px"></el-input>
          </div>
          <div class="search"
               @click="search">
            SEARCH
          </div>
        </div>
        <div class="master-view-third-container-main"
             v-if="articleList.length > 0">
          <div class="master-view-third-container-main-item"
               v-for="(item, index) in articleList"
               :key="index"
               @click="linkTo(item.link, '_blabk')">
            <p>
              {{ item.title }}
            </p>
            <p>
              {{ item.modified.split(' ')[0] }} >
            </p>
          </div>
        </div>
      </div>
    </div>
    <activities style="minHeight: 600px" />
    <!-- <Common-activies></Common-activies> -->
    <Common-footer></Common-footer>
  </div>
</template>

<script>
import Swiper from 'swiper';
import 'swiper/css/swiper.min.css';
import CommonFooter from '../components/common-footer';
import activities from '../components/activities.vue';
// import news from '../components/news.vue';
// import CommonActivies from '../components/common-activities';

export default {
  name: 'master-view',
  data () {
    return {
      input: '',
      articleList: [],
      masterView: []
    }
  },
  created () {
    this.$api.getArticles({
      category: 'opinion',
      num: 8
    }).then(res => {
      this.articleList = res.articles;
    });
  },
  mounted () {
    this.$api.getExpert_opinions().then(res => {
      this.masterView = res.expert_opinions;
      this.$nextTick(() => {
        new Swiper('.master-view-second-container', {
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
        })
      })
    });
  },
  computed: {
  },
  methods: {
    linkTo (url, type) {
      if (type) {
        window.open(url, type);
      } else {
        this.$router.push({
          name: url
        })
      }
    },
    toAboutUsDetails () {
      this.$router.push({
        name: 'aboutUs/details'
      })
    },
    search () {
      if (this.input) {
        this.$api.getArticles({
          category: 'opinion',
          q: this.input,
          num: 8
        }).then(res => {
          this.articleList = res.articles;
        })
      } else {
        this.$api.getArticles({
          category: 'opinion',
          num: 8
        }).then(res => {
          this.articleList = res.articles;
        })
      }
    }
  },
  components: {
    CommonFooter,
    // CommonActivies,
    activities,
    // news
  }
}
</script>
